<template>
  <div>
    <div class="title-wrap">
      <div class="title">
        Membership
      </div>
      <div>
        <DefaultButton
        :content="'멤버쉽 즉시 구매하기'"
        :class-list="'primary-btn'"
        :on-click="moveMembershipMarket"></DefaultButton>
      </div>
    </div>
    <div class="background-img"></div>
    <div class="content-wrap">
      <div class="round-box">
        <div class="small-title">
          LCWC Universe
        </div>
        <div class="lcwc-content-wrap">
          <div class="main-title">
            <div>
              <b class="lcwc-title lcwc-title-pc">Lazy Cat Wine Club</b>
              <b class="lcwc-title lcwc-title-mobile">Lazy Cat Wine Club</b>
            </div>
            <div class="lcwc-content">
              <p style="margin: 0">우리와 다른 지구, 인간과 지능이 같은 고양이들이 살아가는 세상. 그 중 와인에 빠진 상위 1% 고양이들의 모임 Lazy Cat Wine Club. LCWC 멤버들은 항상 똑같은 곳에서 와인을 마시는걸 싫어하죠. 서로 다른 멋진 곳에서 와인을 마시고 사진을 찍는게 이들의 삶의 낙입니다. 우리도 LCWC 멤버들처럼 세계의 멋진 곳에서 와인을 즐겨볼까요?</p>
            </div>
          </div>
          <div>
            <div class="lcwc-image"></div>
          </div>
        </div>
      </div>

      <div class="membership-feature">
        <div class="small-title">
          Winex Membership
        </div>
        <div class="main-title">
          <div class="title">
            <p>와넥스의 파인 와인 NFT</p>
            <b>LCWC 멤버쉽으로 200% 즐겨보세요! </b>
          </div>
          <div class="content">
            <p>LCWC는 Winex 플랫폼의 멤버쉽 NFT로 총 1만개가 발행되었고, 각각의 PFP는 모두 다른 개성을 가지고 있습니다. LCWC NFT를 독점 소유하면 Winex 플랫폼에서 다양한 혜택을 받을 수 있으며 각각은 모두 독자적으로 거래가 가능합니다.</p>
          </div>
        </div>

        <div class="grid-items">

          <div class="item" v-for="item in featureList" v-bind:key="item.subject">
            <div class="icon icon-pc">
              <img :src="item.icon">
            </div>
            <div class="icon icon-mobile">
              <img :src="item.iconMo">
            </div>
            <div class="text-area">
              <div class="subject">
                {{item.subject}}
              </div>
              <div class="text text-pc" v-html="item.text">

              </div>
              <div class="text text-mobile" v-html="item.textMo">

              </div>
            </div>
          </div> <!-- item -->

        </div>
      </div>

      <div class="club">
        <div class="title">
          Lazy Cat Wine Club
        </div>
        <div class="gallery">
          <div class="grid-items">
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-1.png')})`}"></div>
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-2.png')})`}"></div>
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-3.png')})`}"></div>
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-4.png')})`}"></div>
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-5.png')})`}"></div>
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-6.png')})`}"></div>
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-7.png')})`}"></div>
            <div class="profile" :style="{backgroundImage: `url(${require('@/assets/images/club/membership/member-8.png')})`}"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import DefaultButton from "../../components/buttons/DefaultButton";
export default {
  components: {DefaultButton},
  created() {

  },
  data () {
    return  {
      featureList: [
        {
          subject:"민팅 참여 가능",
          text:"파인 와인 NFT<br/>민팅 참여 가능",
          textMo:"파인 와인 NFT 민팅 참여 가능",
          icon:require("@/assets/images/icon/membership/pc/ic-minting.png"),
          iconMo:require("@/assets/images/icon/membership/pc/ic-minting.png"),
        },
        {
          subject:"거래 수수료 할인",
          text:"NFT 거래 수수료<br/>4% 에서 2%로 할인",
          textMo:"NFT 거래 수수료 4% 에서 2%로 할인",
          icon:require("@/assets/images/icon/membership/pc/ic-tag.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-tag.png"),
        },
        {
          subject:"운송비 3년간 무료",
          text:"한국으로 와인 냉장<br/>컨테이너 운송비 3년간 무료",
          textMo:"한국으로 와인 운송비 3년간 무료",
          icon:require("@/assets/images/icon/membership/pc/ic-truck.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-truck.png"),
        },
        {
          subject:"보관료 3년간 무료",
          text:"프랑스 현지 와인 창고<br/>보관료 3년간 무료",
          textMo:"프랑스 현지 와인 창고 보관료 3년간 무료",
          icon:require("@/assets/images/icon/membership/pc/ic-box.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-box.png"),
        },
        {
          subject:"와이너리 현지 투어",
          text:" 현지 와이너리 투어 <br/>by Winex Council",
          textMo:"현지 와이너리 투어 by Winex Council",
          icon:require("@/assets/images/icon/membership/pc/ic-fly.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-fly.png"),
        },
        {
          subject:"테이스팅 & 세미나",
          text:"스페셜 파인 와인<br/>테이스팅 & 세미나 by WC",
          textMo:"스페셜 파인 와인 테이스팅, 세미나 by WC",
          icon:require("@/assets/images/icon/membership/pc/ic-wine.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-wine.png"),
        },
        {
          subject:"파티 참여",
          text:"럭셔리 프라이빗 파티<br/>with WC",
          textMo:"럭셔리 프라이빗 파티 with WC",
          icon:require("@/assets/images/icon/membership/pc/ic-party.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-party.png"),
        },
        {
          subject:"제휴 업체 혜텍",
          text:"와인 구매시 할인, 콜키지 프리 등<br/>다양한 제휴 업체 혜택",
          textMo:"와인 할인, 콜키지 프리 등 제휴 할인 혜택",
          icon:require("@/assets/images/icon/membership/pc/ic-gift.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-gift.png"),
        },
        /*{
          subject:"NFT 자유 거래 가능",
          text:"그 자체로 리미티드 에디션이며<br/>자유롭게 거래 가능",
          textMo:"리미티드 에디션 자유롭게 거래 가능",
          icon:require("@/assets/images/icon/membership/pc/ic-eth.png"),
          iconMo:require("@/assets/images/icon/membership/mobile/ic-eth.png"),
        },*/
      ]
    }
  },
  methods: {
    moveMembershipMarket() {
      this.$router.push('/market/membership/list?page=1&order=1&sub_category_no=219,')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/membership.scss";
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 769px) {
      padding-bottom: 22px;
    }
    .title {
      @media (max-width: 769px) {
        padding: 0;
      }
    }
  }
</style>
